/* Customize Ant Design Table */

.ant-table {
  border: 1px solid #f0f0f0;
  border-radius: 8px;
}

.ant-table-thead > tr > th {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.88);
  border-bottom: 1px solid #f0f0f0;
  padding: 16px;
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
  padding: 16px;
  font-size: 14px;
}

.ant-table-tbody > tr:hover > td {
  background-color: #fafafa;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: #e6f4ff;
}

.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background-color: #bae0ff;
}

.ant-input, .ant-select, .ant-picker {
  background-color: #ffffff;
}

/* Add some margin around the dropdowns for better spacing */
.ant-select {
  margin: 0 5px;
}

/* Style the table rows and cells to ensure proper borders and spacing */
.ant-table-cell {
  border-bottom: 1px solid #f0f0f0; /* Add bottom border to table cells */
}

.ant-table-thead > tr > th {
  background-color: #f0f0f0; /* Ensure table header has a light background */
  border-bottom: 1px solid #d9d9d9; /* Add bottom border to header cells */
}

.ant-table-tbody > tr:hover > td {
  background-color: #fafafa; /* Add hover effect to table rows */
}
